const logger = {
  trace: process.env.NEXT_PUBLIC_ENV == 'prod' ? () => null : console.trace,
  debug: process.env.NEXT_PUBLIC_ENV == 'prod' ? () => null : console.debug,
  log: process.env.NEXT_PUBLIC_ENV == 'prod' ? () => null : console.log,
  info: console.info,
  warn: console.warn,
  error: console.error,
}

export default logger;
export {logger};
